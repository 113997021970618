import React, { useEffect, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { maneuverIcons } from '../lib/maneuverIcons';

const DirectionsList = ({ source, destination }) => {
  const [steps, setSteps] = useState([]);
  const [distance, setDistance] = useState('');

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries: ['places'],
  });

  useEffect(() => {
    if (isLoaded && source?.name && destination?.name) {
      calculateRoute(source?.name, destination?.name);
    }
  }, [destination?.name, isLoaded, source?.name]);

  const calculateRoute = async (origin, destination) => {
    try {
      const directionsService = new window.google.maps.DirectionsService();
      const result = await directionsService.route({
        origin,
        destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
      });
      if (result.status === 'OK') {
        const steps = result.routes[0].legs[0].steps.map((step) => ({
          instructions: step.instructions,
          maneuver: step.maneuver || 'default',
          distance: step.distance
            ? (step.distance.value / 1000).toFixed(2) + ' KM'
            : 'N/A',
        }));
        setSteps(steps);
        setDistance(result.routes[0].legs[0].distance.text);
      }
    } catch (error) {
      console.error('Error fetching directions:', error);
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.route}>
        Route: {`"${source?.name}" To "${destination?.name}"`}
      </h2>

      {/* Scrollable List */}
      <ul style={styles.list}>
        <li style={styles.listItem}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={maneuverIcons.start}
              alt="Start"
              style={{ width: '20px', marginRight: '10px' }}
            />
            {source?.name}
          </div>
        </li>

        {steps.map((step, index) => (
          <li key={index} style={styles.listItem}>
            <div>
              {step.maneuver !== 'default' && (
                <img
                  src={maneuverIcons[step.maneuver]}
                  alt={step.maneuver}
                  style={{ width: '20px', marginRight: '10px' }}
                />
              )}
              <span
                dangerouslySetInnerHTML={{ __html: step.instructions }}
                style={styles.instruction}
              />
            </div>
            <span style={styles.distance}>{step.distance}</span>
          </li>
        ))}

        <li style={styles.listItem}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={maneuverIcons.end}
              alt="End"
              style={{ width: '20px', marginRight: '10px' }}
            />
            {destination?.name}
          </div>
        </li>
      </ul>
    </div>
  );
};

const styles = {
  container: {
    backgroundColor: '#fff',
    padding: '10px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '500px',  // Adjust the width as needed
    margin: '0 auto',
  },

  route: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#333',
    marginBottom: '10px',
  },

  list: {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    maxHeight: '250px',     // Fixed height for scrollable area
    overflowY: 'auto',      // Enable vertical scrolling
    border: '1px solid #ddd',
    borderRadius: '6px',
    backgroundColor: '#f9f9f9',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
    padding: '10px',
    backgroundColor: '#fff',
    borderBottom: '1px solid #eee',
    justifyContent: 'space-between',
  },

  icon: {
    fontSize: '20px',
    marginRight: '10px',
  },

  instruction: {
    fontSize: '14px',
    color: '#444',
  },

  distance: {
    fontSize: '12px',
    color: '#666',
    marginLeft: '10px',
  },
};

export default DirectionsList;
