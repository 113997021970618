import React, { useState, useEffect } from 'react';
import './App.scss';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';
import Header from './components/Header';
import MapComponent from './components/MapContainer';
import SearchOptions from './components/SearchOption';
import StationDetails from './components/StationDetails';
import { getPromotionLinks } from './store/actions/stationActions';
import { useDispatch } from 'react-redux';
import CookieDisclaimer from './components/CookieDisclaimer';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrintableRoutes from './components/PrintableRoutes';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import TripPlanner from './components/TripPlanner';
import { MAP_VIEWS } from './store/constants/mapViewConstants';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Footer from './components/Footer';
import ThemeRegistry from './components/ThemeRegistry';
import { clearLocalStorage } from './lib/helper';
import { DEFAULT_FILTER } from './store/constants';

function App() {
  const dispatch = useDispatch();
  const [mapCenter, setMapCenter] = useState({ lat: 38.8951, lng: -77.0364 });
  const [selectedStation, setSelectedStation] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [filterValues, setFilterValues] = useState(DEFAULT_FILTER);
  console.log('App ~ filterValues:', filterValues);
  const [position, setPosition] = useState(null);
  const [sourceCity, setSourceCity] = useState(
    JSON.parse(window?.localStorage?.getItem('SOURCE_CITY') || `{}`)
  );
  const [destinationCity, setDestinationCity] = useState(
    JSON.parse(window?.localStorage?.getItem('DESTINATION_CITY') || `{}`)
  );
  const [routeDetails, setRouteDetails] = useState();
  console.log('App ~ routeDetails:', routeDetails);
  const [destinations, setDestinations] = useState(
    JSON.parse(window?.localStorage?.getItem('DESTINATIONS') || `[]`)
  );
  const [mapView, setMapView] = useState(MAP_VIEWS.normal);

  const [tripPlannerOpen, setTripPlannerOpen] = useState(false);
  const [buildTrip, setBuildTrip] = useState(0);
  const [clearTrip, setClearTrip]= useState(0);

  const handleGetDirectionsClick = (station) => {
    if (destinationCity || destinations?.length) {
      return toast.warn(
        'You cannot get directed instead you can add this to your trip.'
      );
    }
    setDestinationCity({
      lat: station?.Latitude,
      lng: station?.Longitude,
      name: station?.StationName,
    });
    setTripPlannerOpen(true);
    handleShowLocation();
  };

  const handleLocationSelect = (location) => {
    setMapCenter(location);
    setPosition(location);
  };

  const handleStationSelect = (station) => {
    setPosition(null);
    setSelectedStation(station);
  };

  const handleOpenModal = (station) => {
    setOpenModal(true);
  };

  const handleClearTrip = () => {
    if (window?.localStorage) {
      clearLocalStorage();
    }
    setDestinationCity(null);
    setSourceCity(null);
    setRouteDetails(null);
    setDestinations([]);

    setMapCenter(null);
    setPosition(null);
  };

  const handleShowLocation = () => {
    if (navigator.geolocation) {
      navigator.permissions.query({ name: 'geolocation' }).then((result) => {
        if (destinationCity && sourceCity) {
          handleClearTrip();
        }
        setTripPlannerOpen(false);
        if (result.state === 'denied') {
          toast.error('Please enable location services to use this feature.');
        } else {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const { latitude, longitude } = pos.coords;
              const userLocation = { lat: latitude, lng: longitude };
              setSourceCity(userLocation);
              setMapCenter(userLocation);
              setPosition(userLocation);
              toast.success('Location retrieved successfully!');
            },
            () => {
              toast.error('Unable to retrieve location.');
            },
            {
              enableHighAccuracy: true,
              timeout: 10000,
              maximumAge: 0,
            }
          );
        }
      });
    } else {
      toast.error('Geolocation is not supported by your browser.');
    }
  };

  const handleTripPlanner = async () => {
    try {
      if (!tripPlannerOpen) {
        setMapCenter(null);
        setPosition(null);
      }
      setTripPlannerOpen(!tripPlannerOpen);
    } catch (err) {
      console.error('handleTripPlanner ~ err:', err);
    }
  };

  const getLocationName = async (latitude, longitude) => {
    const response = await fetch(
      `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    );
    if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);

    const data = await response.json();
    if (data?.display_name) {
      return {
        lat: latitude,
        lng: longitude,
        name: data.display_name,
      };
    } else {
      console.error(
        'Error fetching location name:',
        data.error || 'No data returned'
      );
      return {};
    }
  };

  const getCurrentLocationAndShow = async () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
  
          try {
            const locationWithName = await getLocationName(latitude, longitude);
  
            if (locationWithName && locationWithName.lat && locationWithName.lng) {
              setSourceCity(locationWithName);  // Update state
              setPosition(locationWithName);    // Update position for map
              resolve(locationWithName);        // Return the location
            } else {
              reject("Failed to fetch location name.");
            }
          } catch (error) {
            console.error('Error fetching location name:', error);
            reject("Error fetching location name.");
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            navigator.permissions.query({ name: 'geolocation' }).then((result) => {
              if (result.state === 'prompt') {
                navigator.geolocation.getCurrentPosition(
                  async (position) => {
                    const { latitude, longitude } = position.coords;
  
                    try {
                      const locationWithName = await getLocationName(latitude, longitude);
  
                      if (locationWithName && locationWithName.lat && locationWithName.lng) {
                        setSourceCity(locationWithName);  // Update state
                        setPosition(locationWithName);    // Update position for map
                        resolve(locationWithName);        // Return the location
                      } else {
                        reject("Failed to fetch location name.");
                      }
                    } catch (err) {
                      console.error('Error fetching location name:', err);
                      reject("Error fetching location name.");
                    }
                  },
                  (err) => {
                    console.error('Error getting location:', err);
                    reject("Failed to retrieve location.");
                  }
                );
              } else {
                reject("Location permission denied.");
              }
            });
          } else {
            console.error('Error getting location:', error);
            reject("Failed to retrieve location.");
          }
        }
      );
    });
  };
  
  const handleAddToTrip = async (station) => {
    try {
      let currentSourceCity = sourceCity;  // Use a local variable for sourceCity
  
      // If sourceCity isn't set, try to get the current location
      if (!currentSourceCity || !position) {
        try {
          currentSourceCity = await getCurrentLocationAndShow();  // Fetch and store location in local variable
        } catch (locationError) {
          toast.error("Unable to retrieve current location. Please enable location services.");
          return;
        }
      }
  
      // If destinationCity isn't set, set the station as the destination
      if (!destinationCity || (destinationCity && Object.keys(destinationCity).length === 0)) {
        setDestinationCity({
          lat: station?.Latitude,
          lng: station?.Longitude,
          name: station?.StationName,
        });
  
        toast.success(`${station?.StationName} set as destination!`);
        setTripPlannerOpen(true);  // Open Trip Planner after setting destination
        return;
      }
  
      // If both source and destination are set, add station as an intermediate destination
      const alreadyAdded = destinations.some(
        (dest) => dest.lat === station?.Latitude && dest.lng === station?.Longitude
      );
  
      if (alreadyAdded) {
        toast.warn("This station is already in your trip.");
        return;
      }
  
      setDestinations((prevDestinations) => [
        ...prevDestinations,
        {
          lat: station?.Latitude,
          lng: station?.Longitude,
          name: station?.StationName,
        },
      ]);
  
      setTripPlannerOpen(true);  // Open Trip Planner if adding intermediate stops
    } catch (error) {
      console.error('handleAddToTrip ~ error:', error);
      toast.error('Failed to add station to the trip. Please try again.');
    }
  };
  
  

  useEffect(() => {
    getCurrentLocationAndShow();
  }, []);

  useEffect(() => {
    dispatch(getPromotionLinks());
  }, [dispatch]);

  useEffect(() => {
    if (destinations?.length && window.localStorage) {
      window?.localStorage?.setItem(
        'DESTINATIONS',
        JSON.stringify(destinations)
      );
    }

    if (sourceCity && window?.localStorage) {
      window?.localStorage?.setItem('SOURCE_CITY', JSON.stringify(sourceCity));
    }
    if (destinationCity && window?.localStorage) {
      window?.localStorage?.setItem(
        'DESTINATION_CITY',
        JSON.stringify(destinationCity)
      );
    }
  }, [destinations, sourceCity, destinationCity]);

  return (
    <ThemeProvider theme={ThemeRegistry}>
      {/* <DirectionsList source={sourceCity} destination={destinationCity} /> */}
      <CssBaseline />
      <BrowserRouter>
        <Routes>
          <Route
            path='/'
            element={
              <Box className='App'>
                <ToastContainer />
                <Header
                  mapCenter={mapCenter}
                  onLocationSelect={handleLocationSelect}
                  handleShowLocation={handleShowLocation}
                  setSourceCity={setSourceCity}
                  setDestinationCity={setDestinationCity}
                  setRouteDetails={setRouteDetails}
                  handleTripPlanner={handleTripPlanner}
                  setMapView={setMapView}
                  tripPlannerOpen={tripPlannerOpen}
                />
                <Box className='map-section'>
                  <MapComponent
                    filterValues={filterValues}
                    center={mapCenter}
                    onStationSelect={handleStationSelect}
                    onOpenModal={handleOpenModal}
                    position={position}
                    handleShowLocation={handleShowLocation}
                    sourceCity={sourceCity}
                    destinationCity={destinationCity}
                    setRouteDetails={setRouteDetails}
                    routeDetails={routeDetails}
                    setSourceCity={setSourceCity}
                    destinations={destinations}
                    mapView={mapView}
                    buildTrip={buildTrip}
                    clearTrip={clearTrip}
                    setClearTrip={setClearTrip}
                  />
                  <SearchOptions
                    filterValues={filterValues}
                    setFilterValues={setFilterValues}
                    routeDetails={routeDetails}
                  />

                  {tripPlannerOpen && (
                    <TripPlanner
                      sourceCity={sourceCity}
                      destinationCity={destinationCity}
                      setDestinationCity={setDestinationCity}
                      setSourceCity={setSourceCity}
                      onClose={() => {
                        setTripPlannerOpen(false);
                      }}
                      destinations={destinations}
                      setRouteDetails={setRouteDetails}
                      routeDetails={routeDetails}
                      setDestinations={setDestinations}
                      setBuildTrip={setBuildTrip}
                      setClearTrip={setClearTrip}
                    />
                  )}
                  <StationDetails
                    station={selectedStation}
                    isOpenModal={openModal}
                    setOpenModal={setOpenModal}
                    onGetDirectionsClick={handleGetDirectionsClick}
                    handleAddToTrip={handleAddToTrip}
                  />
                </Box>
                <Footer />
                {/* <CookieDisclaimer /> */}
              </Box>
            }
          />
          <Route
            path='/printable-routes'
            element={
              <PrintableRoutes
                destinations={destinations}
                routeDetails={routeDetails}
                sourceName={sourceCity?.name}
                destinatioinName={destinationCity?.name}
              />
            }
          />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
