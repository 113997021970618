import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet'; // Import Leaflet
import 'leaflet/dist/leaflet.css';
import 'leaflet.gridlayer.googlemutant'; // Import GoogleMutant plugin

const GoogleMapsLayer = ({ type }) => {
  const map = useMap();

  useEffect(() => {
    // Add Google Mutant layer
    const googleLayer = L.gridLayer.googleMutant({
      type, // Options: roadmap, satellite, hybrid, terrain
    });

    map.addLayer(googleLayer);

    const trafficLayer = L.tileLayer(
      `https://mt1.google.com/vt/lyrs=m,traffic&x={x}&y={y}&z={z}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
      {
        attribution:
          '&copy; <a href="https://www.google.com/maps">Google Maps</a>',
      }
    );

    type === 'traffic' && map.addLayer(trafficLayer);

    return () => {
      if(map && map?.removeLayer){

        map?.removeLayer(googleLayer); // Cleanup on unmount
        type === 'traffic' && map?.removeLayer(trafficLayer);
      }
    };
  }, [map, type]);

  return null;
};

export default GoogleMapsLayer;
