import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  useMap,
} from "react-leaflet";
import L from "leaflet";
import CLPin from "../../assets/img/C_L_Pin.png";
import CPin from "../../assets/img/C_Pin.png";
import LPin from "../../assets/img/L_Pin.png";
import "leaflet/dist/leaflet.css";
import "./style.scss";
import { filterServerData } from "../../lib/helper";
import Routing from "../../components/TripPlanner/routing";
import STATIONS from "../../Data/stations.json";
import { MARKERS } from "../../assets/markers/markers";
import { Box, IconButton } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import ZoomControlBtn from "../ZoomControlBtn";
import GoogleMapsLayer, { TrafficLayer } from "../GoogleMapsComponent";

const CenterComponent = ({ center }) => {
  const map = useMap();

  useEffect(() => {
    if (center) {
      map.setView(center, map.getZoom());
    }
  }, [center, map]);

  return null;
};

const ZoomToLocation = ({ coordinates }) => {
  const map = useMap();

  // Set the map's view to the selected coordinates with a zoom level of 12
  map.setView([coordinates.lat, coordinates.lng], 10);

  return null;
};

const LocationMarker = ({ position }) => {
  // Set a custom marker icon (optional)
  const customIcon = new L.Icon({
    iconUrl: MARKERS[0],
    iconSize: [60, 60],
    iconAnchor: [30, 60],
  });

  return position === null ? null : (
    <Marker position={position} icon={customIcon}>
      <Tooltip>You are here</Tooltip>
    </Marker>
  );
};

const MapComponent = ({
  filterValues,
  center,
  onStationSelect,
  onOpenModal,
  position,
  sourceCity,
  destinationCity,
  setRouteDetails,
  routeDetails,
  setSourceCity,
  destinations,
  mapView,
  buildTrip,
  clearTrip,
  setClearTrip
}) => {
  console.log("mapView:", mapView);
  const [stations, setStations] = useState(STATIONS);

  const createIcon = (iconUrl) => {
    return L.icon({
      iconUrl,
      iconSize: [25, 35],
      iconAnchor: [12, 35],
      popupAnchor: [0, -30],
    });
  };

  const getPin = (station) => {
    if (
      station?.StationFuelTypeCNG === "Yes" &&
      station?.StationFuelTypeLNG === "No"
    ) {
      return createIcon(CPin);
    }
    if (
      station?.StationFuelTypeLNG === "Yes" &&
      station?.StationFuelTypeCNG === "No"
    ) {
      return createIcon(LPin);
    }
    if (
      station?.StationFuelTypeCNG === "Yes" &&
      station?.StationFuelTypeLNG === "Yes"
    ) {
      return createIcon(CLPin);
    }
    return false;
  };

  const handleStationClick = (station) => {
    if (onStationSelect) {
      onOpenModal(true);
      onStationSelect(station);
    }
  };

  const filterStation = filterServerData(filterValues, stations);

  return (
    <MapContainer
      center={position || center}
      zoom={4}
      zoomControl={true}
      style={{ width: "100%", height: "100%" }}
    >
      <GoogleMapsLayer type={mapView} />

      <CenterComponent center={position} />

      {position?.lat && position?.lng && (
        <ZoomToLocation coordinates={position} />
      )}
      {position?.lat && position?.lng && <LocationMarker position={position} />}

      {filterStation?.map((station, index) => {
        const lat = parseFloat(station?.Latitude);
        const lng = parseFloat(station?.Longitude);
        if (isNaN(lat) || isNaN(lng)) {
          console.error(
            `Invalid coordinates for station: ${station?.StationName}`
          );
          return null;
        }
        return (
          getPin(station) && (
            <Marker
              key={index}
              position={[lat, lng]}
              icon={getPin(station)}
              eventHandlers={{
                click: () => {
                  handleStationClick(station);
                },
              }}
            ></Marker>
          )
        );
      })}
      <LocationMarker position={position} />
      <ZoomControlBtn />
      <Routing
        sourceCity={sourceCity}
        destinationCity={destinationCity}
        setSourceCity={setSourceCity}
        destinations={destinations}
        setRouteDetails={setRouteDetails}
        buildTrip={buildTrip} // <-- Add this
        clearTrip={clearTrip}
      />
    </MapContainer>
  );
};

export default MapComponent;
